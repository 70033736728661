<template>
  <v-dialog
    v-model="displayDialog"
    width="800"
    :overlay-opacity="0.8"
    @keydown.esc.stop.prevent="displayDialog = false"
    @click:outside="displayDialog = false"
  >
    <!-- The card ensures that the background is correct -->
    <v-card>
      <v-card-title
        >Ullamco dolor veniam et exercitation dolor ipsum pariatur magna
        voluptate tempor irure.</v-card-title
      >
      <v-card-text>
        <v-text-field
          label="From *"
          v-model="emailFrom"
          :rules="rules"
        ></v-text-field>
        <v-text-field
          label="To *"
          v-model="emailTo"
          :rules="rules"
        ></v-text-field>
        <v-textarea
          :rows="2"
          auto-grow
          v-model="emailBody"
          label="Body"
        ></v-textarea>
        <v-expansion-panels v-if="emailPageContent">
          <v-expansion-panel>
            <v-expansion-panel-header> Content </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div
                v-html="emailPageContent"
                class="pa-2 elevated email_content"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
      <v-card-actions class="d-flex justify-end">
        <v-spacer />
        <v-btn @click="$emit('close')" aut-action-cancel> Cancel </v-btn>
        <v-btn @click="sendEmail">Send</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { rules } from "@/util.js";
import { pageMixin } from "@/components/mixin.js";
import { mapState } from "vuex";
const debug = require("debug")("atman.components.email_page_dialog");
debug(`page_dialog`);
export default {
  name: "EmailPageDialog",
  mixins: [pageMixin],
  data() {
    let applicableRules = [rules.mandatory(), rules.email()];
    return {
      rules: applicableRules,
      emailFrom: "",
      emailTo: "",
      emailBody: "",
    };
  },
  computed: {
    ...mapState(["emailPageContent"]),
    displayDialog: {
      get() {
        return this.$store.state.emailPage;
      },
      set(value) {
        return this.$store.commit("toggleEmailPageDialog", value);
      },
    },
  },
  methods: {
    sendEmail() {
      debug(`sendEmail invoked`);
    },
  },
};
</script>
<style lang="scss" scoped>
.email_content {
  ::v-deep .behavior_dont_include_in_email {
    display: none !important;
  }
}
</style>
